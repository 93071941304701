// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
const queryString = require('query-string')
import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

jQuery(document).on('theme:init', () => {
  // Engagement type selector on project search form
  jQuery('.project-search-engagement-type-filter').on('click', (event) => {
    event.preventDefault()
    const engagement = event.target.value
    const params = queryString.parse(window.location.search)
    if (engagement === '') {
      delete params.engagement
    } else {
      params.engagement = engagement
    }
    const newUrl = `${window.location.pathname}?${queryString.stringify(params)}`
    window.location.href = newUrl
  })

  // Year selector on project search form
  jQuery('.project-search-year-filter').on('click', (event) => {
    event.preventDefault()
    const year = event.target.value
    const params = queryString.parse(window.location.search)
    if (year === '') {
      delete params.year
    } else {
      params.year = year
    }
    const newUrl = `${window.location.pathname}?${queryString.stringify(params)}`
    window.location.href = newUrl
  })

  // Status selector on project search form
  jQuery('.project-search-status-filter').on('click', (event) => {
    event.preventDefault()
    const status = event.target.value
    const params = queryString.parse(window.location.search)
    if (status === '') {
      delete params.status
    } else {
      params.status = status
    }
    const newUrl = `${window.location.pathname}?${queryString.stringify(params)}`
    window.location.href = newUrl
  })

  // Domain filter for user listing
  jQuery('.users-domain-filter').on('click', (event) => {
    event.preventDefault()
    const domain = event.target.value
    const params = queryString.parse(window.location.search)
    if (domain === '') {
      delete params.domain
    } else {
      params.domain = domain
    }
    const newUrl = `${window.location.pathname}?${queryString.stringify(params)}`
    window.location.href = newUrl
  })
})


